import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPrivileges } from "../service/reducers/PrivelegiesSlice";

const AuthCheck = ({ setIsAuthChecked }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [firstEntry, setFirstEntry] = useState(true);
    const activeTab = useSelector((state) => state.tabs.activeTab);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                if (location.pathname === '/unsubscribe' || location.pathname === '/payment' || location.pathname === '/payment-success' || location.pathname === '/restore-password') {
                    const token = localStorage.getItem('token');
                    if (location.pathname === '/payment-success' && token) {
                        navigate('/personal');
                    } else {
                        setIsAuthChecked(true);
                    }
                    return;
                }
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Токен не найден');
                }
                await dispatch(fetchUserPrivileges(token)).unwrap();
                if (location.pathname === '/' || location.pathname === '/content' || location.pathname === '/content/dashBoard') {
                    navigate('/content/dashboard');
                }
            } catch (error) {
                if (!firstEntry) {
                    navigate('/');
                }
                if (error.message === 'Токен не найден' || error.message?.includes('token has invalid claims: token is expired')) {
                    localStorage.removeItem('token');
                    navigate('/');
                    setFirstEntry(false);
                }
                setFirstEntry(false);
            } finally {
                setTimeout(() => {
                    setIsAuthChecked(true);
                }, 1500);
            }
        };

        checkAuthentication();
    }, [navigate, dispatch, setIsAuthChecked, location.pathname, firstEntry, activeTab]);

    return null;
};

export default AuthCheck;
