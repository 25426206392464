import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Spinner from "../../TestPages/Spinner";
import {
    fetchScatterData,
    increaseLimit,
} from "../../../service/reducers/ScatterNotificationChartSlice";
import { bubbleColors } from "../../../utils/colors";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { formatCurrency } from "../../../utils/rubbleFunc";

const ScatterDiagram = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const svgRef = useRef();
    const yAxisRef = useRef();
    const scrollContainerRef = useRef();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { ScatterData, loading, currentLimit } = useSelector((state) => state.scatterNotificationChartSlice);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const [scrollPosition, setScrollPosition] = useState(0); // состояние для сохранения позиции скролла

    const headerWithTwoButtons = {
        title: 'Процент снижения цен по контрактам',
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useFetchData(fetchScatterData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.scatterNotificationChartSlice.currentLimit),
    ]);

    useEffect(() => {
        if (loading === 'successful' && ScatterData && ScatterData.data) {
            createScatterPlot(ScatterData);
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollLeft = scrollPosition;
            }
        }
        // eslint-disable-next-line
    }, [ScatterData, loading]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer) {
            return;
        }

        const handleScroll = () => {
            if (currentLimit < ScatterData.total) {
                if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
                    if (loading !== 'pending') {
                        dispatch(increaseLimit());
                    }
                }
                setScrollPosition(scrollContainer.scrollLeft);
            }
        };

        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [dispatch, ScatterData, currentLimit, loading]);


    const processData = (data) => {
        const dataPoints = [];
        for (const [key, value] of Object.entries(data)) {
            const declinePerc = value.ArrayDeclinePerc;
            const maxPrice = value.ArrayMaxPrice;
            const numberOrder = value.ArrayNumberOrder;
            const offeredPrice = value.ArrayOfferedPrice;

            for (let i = 0; i < declinePerc.length; i++) {
                dataPoints.push({
                    identifier: key,
                    declinePerc: declinePerc[i],
                    maxPrice: maxPrice[i],
                    numberOrder: numberOrder[i],
                    offeredPrice: offeredPrice[i],
                    participantIndex: i,
                    totalParticipants: declinePerc.length
                });
            }
        }
        return dataPoints;
    };

    const onMouseMove = (event, d) => {
        const tooltipText = [
            { label: 'Номер контракта', value: d.identifier },
            { label: 'Процент снижения', value: `${d.declinePerc.toFixed(2)}%` },
            { label: 'Максимальная цена', value: formatCurrency(d.maxPrice) },
            { label: 'Предложенная цена', value: formatCurrency(d.offeredPrice) },
            { label: 'Порядковый номер', value: d.numberOrder },
        ];

        showTooltip(event, tooltipText);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const createScatterPlot = (rawData) => {
        d3.select(svgRef.current).selectAll("*").remove();
        d3.select(yAxisRef.current).selectAll("*").remove();

        const containerHeight = zoomedDiagram ? 530 : 380;

        const margin = { top: 20, right: 30, bottom: 120, left: 0 };
        const yAxisMargin = { top: 20, right: 0, bottom: 80, left: 50 };
        const effectiveHeight = containerHeight - margin.top - margin.bottom;

        const dataPoints = processData(rawData.data);
        const uniqueIdentifiers = [...new Set(dataPoints.map(d => d.identifier))];

        const contractBandWidth = 80;
        const totalWidth = uniqueIdentifiers.length * contractBandWidth;
        const xScale = d3.scaleBand()
            .domain(uniqueIdentifiers)
            .range([0, totalWidth])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain([0, 100])
            .range([effectiveHeight, 0]);

        const yAxisSvg = d3.select(yAxisRef.current)
            .attr("width", yAxisMargin.left)
            .attr("height", containerHeight);

        const yAxisG = yAxisSvg.append("g")
            .attr("transform", `translate(${yAxisMargin.left}, ${yAxisMargin.top})`);

        const yAxis = d3.axisLeft(yScale).ticks(5);

        yAxisG.call(yAxis)
            .selectAll("text")
            .attr("fill", "#8D96B2");

        yAxisG.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", -yAxisMargin.left + 15)
            .attr("x", -effectiveHeight / 2)
            .attr("dy", "-1em")
            .style("text-anchor", "middle")
            .text("Процент снижения");

        const svg = d3.select(svgRef.current)
            .attr("width", totalWidth + margin.left + margin.right)
            .attr("height", containerHeight);

        const chartG = svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        chartG.selectAll(".contract-bg")
            .data(uniqueIdentifiers)
            .enter()
            .append("rect")
            .attr("class", "contract-bg")
            .attr("x", d => xScale(d))
            .attr("y", 0)
            .attr("width", xScale.bandwidth())
            .attr("height", effectiveHeight)
            .attr("fill", (d, i) => i % 2 === 0 ? "#f9f9f9" : "#ffffff");

        const xAxis = d3.axisBottom(xScale).tickSize(0);

        chartG.append("g")
            .attr("transform", `translate(0, ${effectiveHeight})`)
            .call(xAxis)
            .selectAll("text")
            .attr("fill", "#8D96B2")
            .attr("transform", "rotate(-90)")
            .attr("x", -5)
            .attr("y", 0)
            .attr("dy", "0.35em")
            .style("text-anchor", "end");

        const colorScale = d3.scaleOrdinal()
            .domain(uniqueIdentifiers)
            .range(bubbleColors);

        chartG.selectAll(".dot")
            .data(dataPoints)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => {
                const x0 = xScale(d.identifier);
                const bandwidth = xScale.bandwidth();
                return x0 + (d.participantIndex + 1) * (bandwidth / (d.totalParticipants + 1));
            })
            .attr("cy", d => yScale(d.declinePerc))
            .attr("r", 5)
            .attr("fill", d => colorScale(d.identifier))
            .on("mousemove", onMouseMove)
            .on("mouseout", onMouseOut)
            .on("click", (event, d) => {
                event.stopPropagation();
            });

        chartG.selectAll(".winner")
            .data(dataPoints.filter(d => d.numberOrder.includes('Победитель')))
            .enter().append("circle")
            .attr("class", "winner")
            .attr("cx", d => {
                const x0 = xScale(d.identifier);
                const bandwidth = xScale.bandwidth();
                return x0 + (d.participantIndex + 1) * (bandwidth / (d.totalParticipants + 1));
            })
            .attr("cy", d => yScale(d.declinePerc))
            .attr("r", 7)
            .attr("stroke", "black")
            .attr("fill", "none");
    };

    const uniqueIdentifiers = useMemo(() => {
        if (ScatterData && ScatterData.data) {
            return [...new Set(Object.keys(ScatterData.data))];
        }
        return [];
    }, [ScatterData]);
    // eslint-disable-next-line
    const legendData = uniqueIdentifiers.map((id, index) => ({
        label: id,
        color: bubbleColors[index % bubbleColors.length]
    }));

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''}`} style={{ height: zoomedDiagram ? '600px' : 'auto' }}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithTwoButtons}
                    onZoomClick={onZoomClick}
                    diagramName={headerWithTwoButtons.title}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                   {/* <div className={style.header}>
                        <Legend diagramId={"Процент снижения цен по контрактам"} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={bubbleColors} />
                    </div>*/}
                    <div className={style.chartWrapper}>
                        <div className={style.yAxisContainer}>
                            <svg ref={yAxisRef} />
                        </div>
                        <div className={style.scrollContainer} ref={scrollContainerRef}>
                            <svg ref={svgRef} />
                        </div>
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                </>
            )}
        </div>
    );
};

export default ScatterDiagram;
