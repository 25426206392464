import React from 'react';
import trashIcon from '../../../../common/icons/trashIcon.svg';
import style from '../../../../components/SearchCards/SearchCard.module.css';
import { setFormValidity } from "../../../../service/reducers/ModalCloseStatusSlice";
import CustomModalContent from "../../../../components/CustomModalChanger/CustomModal";
import { useModal } from "../../../../hook/useModal";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { PURCHASE } from "../../../../api/config";
import { fetchLikeResults } from "../../../../service/reducers/searchLikesSlice";
import { fetchSearchZData } from "../../../../service/reducers/SearchZSlice";
import { format, parseISO, isValid } from 'date-fns';

const LikeResultCard = ({ data, searchText, highlightText, onOpenSearchResults }) => {
    const { setIsModalVisible, setModalContent, setIsCentered } = useModal();
    const dispatch = useDispatch();

    const deleteButtonStyle = {
        backgroundColor: '#ff0000',
        color: '#ffffff'
    };

    const confirmDeleteAndClose = async (id) => {
        try {
            await axios.delete(`${PURCHASE}/subscribe?id=${id}`, {
                withCredentials: true
            });
        } catch (error) {
            console.error("Request failed", error);
        } finally {
            setIsCentered(false);
            setIsModalVisible(false);
            dispatch(fetchLikeResults());
        }
    };

    const cancelAndClose = () => {
        setIsCentered(false);
        setIsModalVisible(false);
    };

    const handleDeleteAccount = (id, name, event) => {
        event.stopPropagation();
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                title={`Удалить набор "${name}"?`}
                content=""
                buttons={[
                    {
                        text: "Да",
                        onClick: () => confirmDeleteAndClose(id),
                        style: deleteButtonStyle
                    },
                    {
                        text: "Отмена",
                        onClick: cancelAndClose
                    }
                ]}
                onClose={cancelAndClose}
            />
        );

        setIsCentered(true);
        setIsModalVisible(true);
    };

    const handleViewPurchases = async (event) => {
        event.stopPropagation();
        const detailData = data.data || {};

        let startDate = detailData.startDate ? parseISO(detailData.startDate) : null;
        let endDate = detailData.endDate ? parseISO(detailData.endDate) : null;

        if (startDate && isValid(startDate)) {
            startDate = format(startDate, 'yyyy-MM-dd');
        } else {
            startDate = null;
        }

        if (endDate && isValid(endDate)) {
            endDate = format(endDate, 'yyyy-MM-dd');
        } else {
            endDate = null;
        }

        const queryParams = {
            is44FZChecked: detailData.is44FZChecked,
            is223FZChecked: detailData.is223FZChecked,
            isProvisionTrue: detailData.isProvisionTrue,
            isProvisionFalse: detailData.isProvisionFalse,
            isSmp: detailData.isSmp,
            minPrice: detailData.minPrice,
            maxPrice: detailData.maxPrice,
            isTogether: detailData.isTogether,
            customerInn: detailData.customerInn,
            selectedOkpdCodes: detailData.selectedOkpdCodes,
            areas: detailData.areas,
            locations: detailData.locations,
            types: detailData.types,
            startDate,
            endDate,
            searchText: detailData.searchText,
        };

        try {
            await axios.get(`${PURCHASE}/subscribe/check_sub`, {
                params: { id: data.id },
                withCredentials: true
            });
        } catch (error) {
            console.error("Ошибка при отправке запроса check_sub:", error);
        }
        dispatch(fetchSearchZData(queryParams));
        onOpenSearchResults(data.title);
    };


    return (
        <div className={style.card}>
            <div className={style.header}>
                <span className={style.date}>Создан: {new Date(data.created_at).toLocaleDateString()}</span>
                <img
                    src={trashIcon}
                    alt="trash"
                    className={style.trashIcon}
                    onClick={(event) => handleDeleteAccount(data.id, data.title, event)}
                />
            </div>
            <div className={style.detailsContainer}>
                <div className={style.details}>
                    <h3 className={style.title}>{highlightText(data.title, searchText)}</h3>
                    <p>
                        Закупок: {data.purchase_count}
                        <span className={style.contractGrowth}> +{data.new_purchases_count}</span>
                    </p>
                    <span className={style.linkRef} onClick={handleViewPurchases}>Просмотр закупок</span>
                </div>
                <div className={style.buttonContainer}>
                    <button className={`${style.detailsButton} ${style.rightSmallBtn}`}>Подробнее</button>
                </div>
            </div>
        </div>
    );
};

export default LikeResultCard;
