import React, {useEffect, useState} from 'react';
import style from '../Accounts/Accounts.module.css'
import styles from './Groups.module.css'
import {useModal} from "../../../../../hook/useModal";
import CustomModalContent from "../../../../CustomModalChanger/CustomModal";
import plus from '../../../../../common/icons/HiPlus.svg';
import {TableContainer} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import trash from '../../../../../common/icons/trashIcon.svg'
import edit from '../../../../../common/icons/editIcon.svg'
import {setFormValidity} from "../../../../../service/reducers/ModalCloseStatusSlice";
import {useDispatch, useSelector} from "react-redux";
import UserEditForm from "../../../../EditFormGlobal/EditForm";
import GroupWizard from "../../../../Modal/AddGroup/GroupWizard";
import {resetStep} from "../../../../../service/reducers/WizardSlice";
import {deleteGroup} from "../../../../../api/api";
import {selectPrivileges} from "../../../../../service/reducers/PrivelegiesSlice";
import {fetchGroupsData} from "../../../../../service/reducers/userGroupsSlice";
import {fetchCompanyUserData} from "../../../../../service/reducers/soloCompanySlice";
import {fetchOkpdUserData} from "../../../../../service/reducers/userOkpdListSlice";
import {fetchRegionsLkData} from "../../../../../service/reducers/regionsLkSlice";
const Groups = () => {
    const dispatch = useDispatch();
    const { setIsModalVisible, setModalContent, setIsCentered } = useModal();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const wizardStep = useSelector((state) => state.wizardStep.currentStep);
    const [resetGroupWizard, setResetGroupWizard] = useState(null);
    const privileges = useSelector(selectPrivileges);
    const groups = useSelector((state) => state.groupsData.groupsData);
    const [editWizardKey, setEditWizardKey] = useState(0);

    useEffect(() => {
        dispatch(fetchGroupsData());
        dispatch(fetchCompanyUserData());
        dispatch(fetchOkpdUserData());
        dispatch(fetchRegionsLkData());
    }, [dispatch]);

    useEffect(() => {
        if (groups.length > 0) {
            setSelectedGroup(groups[0]);
        }
    }, [groups]);

    const handleClickGroup = (group) => {
        setSelectedGroup(group);
    };

    const handleDeleteGroup = (group, event) => {
        if (privileges.includes('groups.remove')) {
            event.stopPropagation();
            const deleteButtonStyle = {
                backgroundColor: 'red',
            };
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Подтвердите ваши действия!"
                    content={`Вы точно хотите удалить группу "${group.label}" ?`}
                    buttons={[
                        {
                            text: "Да",
                            onClick: async () => {
                                try {
                                    await deleteGroup(group.id);
                                    setIsModalVisible(false);
                                    setIsCentered(false);
                                    dispatch(fetchGroupsData());
                                } catch (error) {
                                    console.error("Не удалось удалить группу:", error);
                                }
                            },
                            style: deleteButtonStyle
                        },
                        {
                            text: "Отмена",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );

            setIsCentered(true);
            setIsModalVisible(true);
        } else {
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Ограничение доступа"
                    content="Вы не можете удалять группы."
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
    };

    const handleRowClick = (groupData) => {
        setEditWizardKey((prevKey) => prevKey + 1);
        const cancelAndClose = () => {
            setIsCentered(false);
            setIsModalVisible(false);
        };
        let formFields = [
            { type: 'input', name: 'label', label: "Название группы", value: groupData.label }
        ];
        if (groupData.type === 'okpd') {
            formFields.push({ type: 'okpdList', name: 'okpds', label: 'ОКПД', value: groupData.label });
        } else if (groupData.type === 'company_suppliers' || groupData.type === 'company_customer') {
            formFields.push({
                type: 'companies',
                name: 'companies',
                label: 'Компании',
                values: groupData.companies.map(company => ({ inn: company.inn, name: company.name })),
                companyType: groupData.type
            });
        } else if (groupData.type === 'region') {
            formFields.push({
                type: 'regions',
                name: 'regions',
                label: 'Регионы',
                values: groupData.regions.map(region => region.subjects)
            });
        } else if (groupData.users && groupData.users.length > 0) {
            formFields.push({ type: 'list', name: 'users', label: 'Пользователи', values: groupData.users.map(user => user.label) });
        }

        setModalContent(
            <CustomModalContent
                key={editWizardKey}
                title="Редактирование группы"
                formComponent={<UserEditForm validaty={true} fields={formFields} groupId={groupData.id} />}
                buttons={[
                    {
                        text: "Сохранить",
                        onClick: cancelAndClose
                    }
                ]}
                onClose={() => {
                    dispatch(fetchGroupsData());
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);
    };

    const handleAddGroupClick = () => {
        if (privileges.includes("groups.create")) {
            dispatch(setFormValidity(true));
            setModalContent(
                <CustomModalContent
                    key={wizardStep}
                    title="Добавление новой группы"
                    formComponent={
                        <GroupWizard
                            onReset={() => setResetGroupWizard({ reset: () => {} })}
                            onClose={() => {
                                if (resetGroupWizard) {
                                    resetGroupWizard.reset();
                                }
                                setIsCentered(false);
                                setIsModalVisible(false);
                                dispatch(resetStep());
                                dispatch(fetchGroupsData());
                            }}
                        />
                    }
                    buttons={[]}
                    onClose={() => {
                        if (resetGroupWizard) {
                            resetGroupWizard.reset();
                        }
                        setIsCentered(false);
                        setIsModalVisible(false);
                        dispatch(resetStep());
                        dispatch(fetchGroupsData());
                    }}
                />
            );

            setIsCentered(true);
            setIsModalVisible(true);
        } else {
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Ограничение доступа"
                    content="Вы не можете добавлять группы."
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <div className={style.availableContainer}>
                <div className={style.plusContainer} onClick={handleAddGroupClick}>
                    <img className={style.plusIco} src={plus} alt="Добавить" />
                    <span>
                        Добавить
                    </span>
                </div>
            </div>
            <div className={style.tableContainer}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={`${style.tableCellText} ${style.column1}`}>Группа</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.column2}`}>Данные (ОКПД/ИНН/Регионы)</TableCell>
                                <TableCell className={`${style.tableCellText}`}>Пользователи</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.column3}`}>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups.map((group) => (
                                <TableRow
                                    key={group.id}
                                    onClick={() => handleClickGroup(group)}
                                    className={`${selectedGroup && selectedGroup.id === group.id ? styles.selectedRow : ''} ${styles.tableRow}`}
                                >
                                    <TableCell className={styles.cellText}>{group.label}</TableCell>
                                    <TableCell>
                                        {group.okpds && group.okpds.length > 0
                                            ? group.okpds.slice(0, 3).map((okpd, index) => <div key={index}>- {okpd.okpd_name} ({okpd.okpd_code})</div>)
                                            : group.companies && group.companies.length > 0
                                                ? group.companies.slice(0, 3).map((company, index) => <div key={index}>- {company.short_name} ({company.inn})</div>)
                                                : group.regions && group.regions.length > 0
                                                    ? group.regions.slice(0, 3).map((region, index) => <div key={index}>- {region.subjects}</div>)
                                                    : group.contract_reg_nums && group.contract_reg_nums.length > 0
                                                        ? group.contract_reg_nums.slice(0, 3).map((num, index) => <div key={index}>- {num}</div>)
                                                        : <div>...</div>}
                                        {(group.okpds && group.okpds.length > 3) || (group.companies && group.companies.length > 3) || (group.regions && group.regions.length > 3) || (group.contract_reg_nums && group.contract_reg_nums.length > 3)
                                            ? <div className={styles.moreText}>и ещё {group.okpds.length > 3 ? group.okpds.length - 3 : group.companies.length > 3 ? group.companies.length - 3 : group.regions.length > 3 ? group.regions.length - 3 : group.contract_reg_nums.length - 3}</div>
                                            : null}
                                    </TableCell>
                                    <TableCell>
                                        {group.users && group.users.length > 0
                                            ? group.users.map((user, index) => <div key={index}>{user.label}</div>)
                                            : <div>...</div>}
                                    </TableCell>
                                    <TableCell>
                                        <div className={style.icoContainer}>
                                            <img className={style.icoTest} src={edit} alt="edit" onClick={(event) => {
                                                event.stopPropagation();
                                                handleRowClick(group);
                                            }} />
                                            <img className={style.icoTest} src={trash} alt="trash" onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteGroup(group, event);
                                            }} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default Groups;
