import {FormControlLabel, LinearProgress} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import styles from '../../../components/EditFormGlobal/EditForm.module.css';
import btnStyle from '../../CustomModalChanger/CustomModal.module.css';
import SearchAutocomplete from "../../SearchAutoComplete/SearchAutoComplete";

export const CompanySearchOrUpload = ({ label, companyType, isFileUpload, setIsFileUpload, handleFileChange, selectedFile, isLoading, uploadProgress, defaultCompanyOptions, searchResults, handleSelectCompany, handleInputChange, inputValue }) => {
    return (
        <>
            <div className={styles.formControl}>
                {label}
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isFileUpload}
                        onChange={() => setIsFileUpload(!isFileUpload)}
                        name="fileUpload"
                        sx={{
                            color: '#4A3DFD',
                            '&.Mui-checked': {
                                color: '#4A3DFD',
                            },
                        }}
                    />
                }
                label="Загрузить файл CSV"
            />
            {isFileUpload ? (
                <div className={styles.formControl}>
                    <label className={styles.fileInputContainer}>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className={styles.hiddenFileInput}
                        />
                        <span className={styles.fileInputText}>
                            {selectedFile ? selectedFile.name : "Загрузите файл с номерами компаний"}
                        </span>
                    </label>
                    {isLoading && (
                        <div className={btnStyle.sendingContainer}>
                            <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '100%' }} />
                            <span>{uploadProgress}%</span>
                        </div>
                    )}
                </div>
            ) : (
                <SearchAutocomplete
                    options={[...defaultCompanyOptions, ...searchResults.map(option => `${option.short_name} (${option.inn})`)]}
                    onChange={handleSelectCompany}
                    onInputChange={handleInputChange}
                    placeholder="Введите название компании"
                    noOptionsText="Ничего не найдено"
                    value={null}
                    inputValue={inputValue}
                />
            )}
        </>
    );
};
