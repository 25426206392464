import styles from "../Layout.module.css";
import SelectedSegments from "../../SelectedSegments/SelectedSegments";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import CommonHeader from "../../Header/ComonHeader";
import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs, resetSearchSuppINNINNINNs, resetSelectedOrganization, resetSelectedSuggestion, setGroupsRed,
    setRelatedINNs, setSelectedComboItem,
    setSelectedOrganization, setSelectedValue, setSearchOrgINNINNs, resetSelectedValue
} from "../../../service/reducers/organizationSlice";
import { MenuItem, Select, ListSubheader } from "@mui/material";
import axios from "axios";
import { fetchOkpdUserData } from "../../../service/reducers/userOkpdListSlice";
import { resetOkpdCode, setOkpdCode } from "../../../service/reducers/selectedComboOkpd";
import { useClearAll } from "../../../hook/useClearAll";
import HeadDashContent from "../../../pages/DashPage/HeadContent/HeadContent";
import { fetchRegionsLkData } from "../../../service/reducers/regionsLkSlice";
import { resetRegionCode, setRegionCode } from "../../../service/reducers/selectedComboRegionSlice";
import {PESONALAREA} from "../../../api/config";
import {
    clearPosition,
    setToggleLock,
    setTogglePosition,
    setToggleVisibility
} from "../../../service/reducers/searchSwticherSlice";
import {cancelAllPendingRequests} from "../../../api/api";

const DashBoard = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isCMBoxFocused, setIsCMBoxFocused] = useState(false);
    const groups = useSelector(state => state.organization.groups);
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData) || [];
    const regionsLkData = useSelector((state) => state.regionsLkData.regionsLkData) || [];
    const selectedValue = useSelector((state) => state.organization.selectedValue);
    const [displayValue, setDisplayValue] = useState("");
    const clearAll = useClearAll();
    const navigate = useNavigate();
    const [isStickyVisible, setIsStickyVisible] = useState(false);
    const selectedSegmentsRef = useRef(null);

    useEffect(() => {
        const currentRef = selectedSegmentsRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsStickyVisible(false);
                } else {
                    setIsStickyVisible(true);
                }
            },
            { threshold: 0 }
        );

        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        dispatch(fetchOkpdUserData());
        dispatch(fetchRegionsLkData());
    }, [dispatch]);

    useEffect(() => {
        if (selectedValue === '') {
            setDisplayValue('');
        } else {
            setDisplayValue(selectedValue);
        }
    }, [selectedValue]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await axios.get(`${PESONALAREA}/personal-area/me_group`, {
                    withCredentials: true
                });
                dispatch(setGroupsRed(response.data));
            } catch (error) {
                console.error("Ошибка при загрузке данных групп", error);
            }
        };

        fetchGroups();

        return () => {
            dispatch(resetSelectedOrganization());
            dispatch(resetRelatedINNs());
        };
    }, [dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const cust_inn = searchParams.get('cust_inn');

        if (cust_inn) {
            cancelAllPendingRequests();
            dispatch(setToggleVisibility(true));
            dispatch(setSearchOrgINNINNs([cust_inn]));
            dispatch(setTogglePosition('supplier'));
            dispatch(setToggleLock(true));
            dispatch(resetSearchSuppINNINNINNs());
            dispatch(resetSelectedOrganization());
            dispatch(resetRelatedINNs());
            dispatch(resetSelectedValue());
            dispatch(resetOkpdCode());
            dispatch(resetRegionCode());
            dispatch(fetchOkpdUserData());
            dispatch(fetchRegionsLkData());
        }
    }, [location.search, dispatch]);

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        let selectedItem = groups.find(group => group.id === selectedValue) ||
            okpdListData.find(item => item.okpd_code === selectedValue) ||
            regionsLkData.find(region => region.iso === selectedValue);
        let valueToStore = '';

        if (selectedItem) {
            dispatch(setSelectedComboItem(selectedItem));
            if (selectedItem.label) {
                valueToStore = selectedItem.label;
            } else if (selectedItem.okpd_code && selectedItem.okpd_name) {
                valueToStore = `${selectedItem.okpd_code} ${selectedItem.okpd_name}`;
            } else if (selectedItem.subjects) {
                valueToStore = selectedItem.subjects;
            }
            dispatch(setSelectedValue(valueToStore));
        } else {
            return;
        }
        dispatch(clearPosition());
        dispatch(resetSelectedOrganization());
        dispatch(resetRelatedINNs());
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetOkpdCode());
        dispatch(resetRegionCode());
        navigate(location.pathname, { replace: true });

        const isOkpd = okpdListData.some(item => item.okpd_code === selectedValue);
        if (isOkpd) {
            dispatch(setOkpdCode(selectedValue));
        } else if (regionsLkData.some(region => region.iso === selectedValue)) {
            dispatch(setRegionCode(selectedValue));
        } else {
            const selectedOrg = groups.find(group => group.id === selectedValue);
            dispatch(setSelectedOrganization(selectedOrg));
            dispatch(setRelatedINNs(selectedValue));
        }

        dispatch(resetSelectedSuggestion());
        clearAll();
    };

    return (
        <div className={styles.mainContainer}>
            <CommonHeader title="Дашборд">
                <Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    value={displayValue}
                    displayEmpty
                    renderValue={selected => {
                        if (!selected) {
                            return <span style={{ color: '#8D96B2' }}>Выберите группу или ОКПД2</span>;
                        }
                        return selected;
                    }}
                    onChange={handleChange}
                    onFocus={() => setIsCMBoxFocused(true)}
                    onBlur={() => setIsCMBoxFocused(false)}
                    sx={{
                        width: '280px',
                        height: '42px',
                        backgroundColor: 'var(--headerCmg-bg-color, #FFF)',
                        transition: 'background-color 0.5s ease-in-out, color 0.5s ease-in-out',
                        borderRadius: '6px',
                        marginLeft: '32px',
                        border: isCMBoxFocused ? '1px solid #4A3DFD' : 'none',
                        color: 'var(--text-color, black)',
                        '& .MuiSelect-select': {
                            paddingLeft: '16px',
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                            color: 'var(--text-color, black)',
                            transition: 'background-color 0.5s ease-in-out',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        },
                        '& .MuiSvgIcon-root': {
                            transition: 'color 0.5s ease-in-out',
                            color: 'var(--text-color, black)',
                        },
                    }}
                >
                    <ListSubheader>Созданные группы</ListSubheader>
                    {groups.filter(group => group.id !== "00000000-0000-0000-0000-000000000000" && (group.companies?.length > 0 || group.okpds?.length > 0 || group.regions?.length > 0 || group.contract_reg_nums?.length > 0)).map(group => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.label}
                        </MenuItem>
                    ))}

                    <ListSubheader>Добавленные ОКПД2</ListSubheader>
                    {okpdListData.map(item => (
                        <MenuItem key={item.okpd_code} value={item.okpd_code}>
                            {`${item.okpd_code} ${item.okpd_name}`}
                        </MenuItem>
                    ))}

                    <ListSubheader>Добавленные регионы</ListSubheader>
                    {regionsLkData.map(region => (
                        <MenuItem key={region.iso} value={region.iso}>
                            {region.subjects}
                        </MenuItem>
                    ))}
                </Select>
            </CommonHeader>
            <HeadDashContent />
            <div ref={selectedSegmentsRef}>
                <SelectedSegments />
            </div>
            {isStickyVisible && (
                <div className={styles.fixedSticky}>
                    <SelectedSegments />
                </div>
            )}
            <div className={styles.contentContainer}>
                <Outlet />
            </div>
        </div>
    )
}

export default DashBoard;
