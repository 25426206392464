import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { convertRangeDateToStartDTFormat } from '../utils/convertRangeDate';
import {selectCurrentLimit} from "../service/reducers/SunKeyChartSlice";
import {selectCurrentLimitBubbles} from "../service/reducers/BubbleChartSlice";

const useRequestData = () => {
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const selectedOkpd = useSelector(state => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector(state => state.productCode.selectedProduct);
    const trimCode = useSelector(state => state.productCode.trimCode);
    const selectedCountryLine = useSelector(state => state.ispOkpd.selectedOkpd);
    const activeRegions = useSelector(state => state.region.activeRegions);
    const pieState = useSelector(state => state.pie.selectedSlice) || [];
    const top = useSelector(state => state.activitySlice);
    const contractTrimCode = useSelector(state => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);

    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const regionComboSelect = useSelector((state) => state.regionComboSelect.regionComboData);

    const storedDates = localStorage.getItem('dateForPickers');
    const dates = storedDates ? JSON.parse(storedDates) : [];
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const { treeMapMode } = useSelector((state) => state.treeMapSlice);
    const mode = useSelector((state) => state.region.mode);
    const roleMode = useSelector((state) => state.respRole.roleMode);
    const currentLimit = useSelector(selectCurrentLimit);
    const selectedTreeMapLabels = useMemo(() => selectedSegments.map(segment => segment.label), [selectedSegments]);
    const regNumArray = useMemo(() => bubbleSegments.map(segment => segment.regNum), [bubbleSegments]);
    const currentLimitBubble = useSelector(selectCurrentLimitBubbles);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || selectedOrganization.type === 'region' || filterOkpd.length > 0 || regionComboSelect.length > 0) && activeTab !== 'Извещения';
    const sunKeyMode = useSelector((state) => state.sunKey.sunKeyMode);
    const pieEtp = useSelector(state => state.pieChartOtpSegment.selectedSlice);
    const selectedMonthStacked = useSelector((state) => state.stackedWithContentSegmentSlice.selectedMonth);
    const limitScatterNotivication = useSelector(state => state.scatterNotificationChartSlice.currentLimit)

        return {
        selectedMonthStacked,
        pieEtp,
        regionComboSelect,
        sunKeyMode,
        roleMode,
        relatedINNs,
        selectedProduct,
        activeRegions,
        topBody: {
            Advantages: top.Advantages,
            Restrictions: top.Restrictions,
            Requirements: top.Requirements,
        },
        trimCode,
        filterOkpd,
        selectedTreeMapLabels,
        selectedMonth,
        activeTab,
        procedureRegNum,
        pieState,
        currentLimitBubble,
        currentLimit,
        dateChanger,
        selectedOkpd,
        contractTrimCode,
        selectedDonutSegmetsV1,
        selectedContractMonth,
        selectedKbrSegments,
        RangeDT,
        selectedCountryLine,
        selectedZoomableSegment,
        regNumArray,
        selectedOrganization,
        treeMapMode,
        shouldShowChangeButton,
        mode,
            limitScatterNotivication,
        ...(slidePoz === 'customer' ? {searchSuppINNINNINNs} : {searchOrgINNINNs})
    };
};

export default useRequestData;
