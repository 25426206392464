import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchScatterNotification} from "../../api/api";

const initialState = {
    ScatterData: [],
    loading: 'idle',
    error: null,
    currentLimit: 300,
};

export const fetchScatterData = createAsyncThunk(
    'scatterNotification/fetchScatterNotification',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetchScatterNotification(options);
        return response.data.result
    }
);

const scatterChartSlice = createSlice({
    name: 'scatterNotification',
    initialState,
        reducers: {
            increaseLimit: (state) => {
                state.currentLimit += 300;
            },
            resetBubbleData: (state) => {
                return initialState;
            },
        },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScatterData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchScatterData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.ScatterData = action.payload;
            })
            .addCase(fetchScatterData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});


export const { increaseLimit } = scatterChartSlice.actions;
export default scatterChartSlice.reducer;
