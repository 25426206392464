import React, { useEffect, useState, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './HeaderContent.module.css';
import PlusIcon from '../../../common/icons/Plusgrey.svg';
import Dots from '../../../common/icons/DotsIcon.svg';
import FilterAllIcon from '../../../common/icons/FilterAllIcon.svg';
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../service/reducers/DiagrammConfig/TabSlice";
import { addRangeDate, cancelAllPendingRequests } from "../../../api/api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomDateButton } from "../../../components/CustomDateButton/CustomDateButton";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { formatISO, addHours } from 'date-fns';
import { updateLocalStorageDates } from "../../../utils/updateLocalStorageDates";
import { setSelectedDate } from "../../../service/reducers/ChangeGlobalDatesSlice";
import FilterIconWithBadge from "../../../components/FilterComponent/FilterBadge";
import { useClearAll } from "../../../hook/useClearAll";
import calendarIcon from '../../../common/icons/calendarIcon.svg';
import { CircleWithLabel } from "../../TestPages/TestPagePayment/CircleWithLabel/CircleWithLabel";
import html2canvas from 'html2canvas';

registerLocale('ru', ru);
setDefaultLocale('ru');

const HeadDashContent = () => {
    const [activeItem, setActiveItem] = useState(0);
    const [sliderStyle, setSliderStyle] = useState({});
    const itemsRef = useRef([]);
    const leftPartRef = useRef(null);
    const dispatch = useDispatch();
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const filterButtonRef = useRef(null);
    const filterComponentRef = useRef(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const menuRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const zoomedDiagram = useSelector((state) => state.zoomedDiagramSlice.zoomedDiagram);

    const [law44, setLaw44] = useState(false);
    const [law223, setLaw223] = useState(false);

    const toggleLaw44 = () => {
        setLaw44(!law44);
    };

    const toggleLaw223 = () => {
        setLaw223(!law223);
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [endDateMinDate, setEndDateMinDate] = useState(new Date(2018, 1, 1));
    const [endDateMaxDate, setEndDateMaxDate] = useState(new Date());
    const maxDate = new Date();
    maxDate.setDate(1);

    const activeFilterCount = 2;
    const clearAll = useClearAll();

    useEffect(() => {
        const storedDates = localStorage.getItem('dateForPickers');
        if (storedDates) {
            const datesArray = JSON.parse(storedDates);
            const startDates = datesArray.filter(date => date.type === 'start_date');
            const endDates = datesArray.filter(date => date.type === 'end_date');
            if (startDates.length > 0) {
                setStartDate(new Date(startDates[0].body));
                const newEndDateMinDate = new Date(startDates[0].body);
                setEndDateMinDate(newEndDateMinDate);
                const newEndDateMaxDate = new Date(newEndDateMinDate);
                newEndDateMaxDate.setFullYear(newEndDateMaxDate.getFullYear() + 3);
                setEndDateMaxDate(newEndDateMaxDate);
            }
            if (endDates.length > 0) {
                setEndDate(new Date(endDates[0].body));
            }
        }
    }, []);

    const handleStartDateChange = async (date) => {
        cancelAllPendingRequests()
        if (endDate && date > endDate) {
            return;
        }
        setStartDate(date);
        setEndDateMinDate(date);

        const newMaxDate = new Date(date);
        newMaxDate.setFullYear(newMaxDate.getFullYear() + 3);
        setEndDateMaxDate(newMaxDate);

        let updatedEndDate = endDate;
        if (endDate > newMaxDate) {
            setEndDate(newMaxDate);
            updatedEndDate = newMaxDate;
        }

        await sendDatesToAPI(date, updatedEndDate);
        updateLocalStorageDates(date, updatedEndDate);
        dispatch(setSelectedDate(date.toISOString()));
    };

    const handleEndDateChange = async (date) => {
        cancelAllPendingRequests()
        if (startDate && date < startDate) {
            return;
        }
        setEndDate(date);
        const startDateToSend = startDate ? startDate : new Date();
        await sendDatesToAPI(startDateToSend, date);
        updateLocalStorageDates(startDateToSend, date);
        dispatch(setSelectedDate(date.toISOString()));
    };

    const sendDatesToAPI = async (start, end) => {
        const formattedStartDate = formatDateToSend(start);
        const formattedEndDate = formatDateToSend(end);
        try {
            await addRangeDate(formattedStartDate, formattedEndDate);
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };

    const handleSaveImages = async () => {
        setIsLoading(true);
        let container;
        if (zoomedDiagram) {
            container = document.getElementById('zoomed-diagram-container');
        } else {
            container = document.getElementById('diagrams-container');
        }
        const originalBackgroundColor = container.style.backgroundColor;
        container.style.backgroundColor = '#F7FAFC';
        const canvas = await html2canvas(container, { useCORS: true, scale: 2 });
        container.style.backgroundColor = originalBackgroundColor;
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'dashboard.png';
        link.click();
        setIsLoading(false);
        setIsMenuVisible(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterComponentRef.current && !filterComponentRef.current.contains(event.target) &&
                !filterButtonRef.current.contains(event.target)) {
                setIsFilterVisible(false);
            }
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const activeIndex = ['Извещения', 'Контракты', 'Исполнение'].indexOf(activeTab);
        cancelAllPendingRequests();
        if (activeIndex !== -1 && activeIndex !== activeItem) {
            handleClick(activeIndex, activeTab);
        }
        if (activeIndex !== -1) {
            const { left: itemLeft, width } = itemsRef.current[activeIndex].getBoundingClientRect();
            const { left: parentLeft } = leftPartRef.current.getBoundingClientRect();
            const left = itemLeft - parentLeft;
            setSliderStyle({ width, left });
        }
        // eslint-disable-next-line
    }, [activeTab]);

    const handleClick = (index, tabName) => {
        if (zoomedDiagram) {
            return;
        }
        if (activeTab !== tabName) {
            dispatch(setActiveTab(tabName));
            clearAll();
        }
        if (activeItem !== index) {
            setActiveItem(index);
            const { left: itemLeft, width } = itemsRef.current[index].getBoundingClientRect();
            const { left: parentLeft } = leftPartRef.current.getBoundingClientRect();
            const left = itemLeft - parentLeft;
            setSliderStyle({ width, left });
        }
    };

    const formatDateToSend = (date) => {
        const dateWithTimezone = addHours(date, 3);
        return formatISO(dateWithTimezone);
    };

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    return (
        <div className={styles.content}>
            <div className={styles.leftPart} ref={leftPartRef}>
                {['Извещения', 'Контракты', 'Исполнение'].map((item, index) => (
                    <div
                        ref={(el) => (itemsRef.current[index] = el)}
                        className={`${styles.item} ${activeItem === index ? styles.activeItem : ''}`}
                        onClick={() => handleClick(index, item)}
                        key={index}
                    >
                        {item}
                    </div>
                ))}
                <div className={styles.slider} style={sliderStyle} />
                <div className={styles.plusIcon}>
                    <img src={PlusIcon} alt="plus" />
                </div>
            </div>
            <div className={styles.iconContainer}>
                <div ref={filterComponentRef} className={`${styles.filterComponent} ${isFilterVisible ? styles.visible : ''}`}>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date(2018, 1, 1)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<CustomDateButton />}
                    />
                    <img className={styles.calendarImg} src={calendarIcon} alt="calendar" />
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={endDateMinDate}
                        maxDate={endDateMaxDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        customInput={<CustomDateButton />}
                    />
                    <div className={styles.checkboxContainer}>
                        <label className={styles.checkboxLabel}>
                            <CircleWithLabel
                                label="223-ФЗ"
                                filled={law44}
                                onClick={toggleLaw44}
                                borderColor="#CCC"
                                activeColor="#4A3DFD"
                                inactiveColor="#EDECFD"
                            />
                        </label>
                        <label className={styles.checkboxLabel}>
                            <CircleWithLabel
                                label="44-ФЗ"
                                filled={law223}
                                onClick={toggleLaw223}
                                borderColor="#CCC"
                                activeColor="#4A3DFD"
                                inactiveColor="#EDECFD"
                            />
                        </label>
                    </div>
                    <div ref={filterButtonRef} className={styles.iconButton} onClick={toggleFilterVisibility}>
                        <FilterIconWithBadge activeFilterCount={activeFilterCount} />
                    </div>
                </div>
                <div ref={filterButtonRef} className={styles.iconButton} onClick={toggleFilterVisibility}>
                    <FilterIconWithBadge activeFilterCount={activeFilterCount} />
                </div>
                <button className={styles.iconButton}>
                    <img src={FilterAllIcon} alt="filter-all" />
                </button>
                <button className={styles.iconButton} onClick={toggleMenu}>
                    <img src={Dots} alt="dots" />
                </button>
                <div
                    ref={menuRef}
                    className={isMenuVisible ? styles.menuVisible : styles.menuHidden}
                >
                    <div className={styles.menuItems}>
                        <div onClick={handleSaveImages}>
                            Скачать
                            {isLoading && (
                                <CircularProgress size={14} className={styles.loader} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeadDashContent;
