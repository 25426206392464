import React from 'react';
import styles from '../Page200/200Page.module.css';
import style from "../../../components/Auth/AuthComponent.module.css";

const UnsubscribePage = () => {
    return (
        <div className={styles.successPage}>
            <div className={style.background}/>
            <div className={styles.container}>
                <h1>Отписка от рассылки</h1>
                <p>Вы действительно хотите отписаться от нашей почтовой рассылки?</p>
                <p>После отписки вы больше не будете получать новости и специальные предложения на свою почту.</p>
                <div className={styles.details}>
                    <button className={styles.goHome} onClick={() => window.location.href = '/'}>Подтвердить отписку</button>
                </div>
            </div>
        </div>
    );
};

export default UnsubscribePage;
