import { createSlice } from "@reduxjs/toolkit";
import { subYears, format } from 'date-fns';


const today = new Date();
const oneYearAgo = subYears(today, 1);

const initialState = {
    searchParams: {
        is44FZChecked: true,
        is223FZChecked: true,
        isProvisionTrue: true,
        isProvisionFalse: false,
        isSmp: false,
        minPrice: 0,
        maxPrice: 0,
        isTogether: false,
        customerInn: [],
        selectedOkpdCodes: [],
        areas: [],
        locations: [],
        types: [],
        startDate: format(oneYearAgo, 'yyyy-MM-dd'),
        endDate: format(today, 'yyyy-MM-dd'),
        searchText: '',
    },
};

const searchFiltersSlice = createSlice({
    name: "searchFilters",
    initialState,
    reducers: {
        setParam: (state, action) => {
            const { key, value } = action.payload;
            if (Array.isArray(state.searchParams[key])) {
                if (!state.searchParams[key].includes(value)) {
                    state.searchParams[key].push(value);
                }
            } else {
                state.searchParams[key] = value;
            }
        },
        removeParam: (state, action) => {
            const { key, value } = action.payload;
            if (Array.isArray(state.searchParams[key])) {
                state.searchParams[key] = state.searchParams[key].filter(item => item !== value);
            } else {
                delete state.searchParams[key];
            }
        },
        clearParams: (state) => {
            state.searchParams = initialState.searchParams;
        },
    },
});

export const { setParam, removeParam, clearParams } = searchFiltersSlice.actions;

export default searchFiltersSlice.reducer;
